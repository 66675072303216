import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { toggleSideModal } from "../../../actions/toggleSideModal"

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
`
const SubtotalWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
`
const Subtotal = styled.p`
  position: relative;
  text-align: center;
  flex-grow: 1;
  margin: 0;
`

const Amount = styled.p`
  position: relative;
  text-align: right;
  margin: 0;
`

const CheckoutPage = styled.div`
  position: relative;
  display: block;
  font-family: Sweet Sans Pro;
  text-align: center;
  text-decoration: none;
  width: 90%;
  margin: 1rem auto;
  color: white;
  border: none;
  border-radius: 10px;
  background-color: var(--ruelo-dark-purple);
  padding: 1rem;
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(0.97, 0.97);
  }
`

const Footer = ({ subtotal, dispatch }) => {
  return (
    <Container>
      <SubtotalWrapper>
        <Subtotal>Subtotal</Subtotal>
        <Amount>${Number(subtotal.amount).toFixed(2)}</Amount>
      </SubtotalWrapper>
      <CheckoutPage
        onClick={() => {
          dispatch(toggleSideModal(false, "cart"))
          navigate("/checkout")
        }}
      >
        Go To Cart
      </CheckoutPage>
    </Container>
  )
}

export default connect()(Footer)
