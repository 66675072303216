import firebase from "gatsby-plugin-firebase"

import getYear from "date-fns/getYear"
import getMonth from "date-fns/getMonth"
import getDate from "date-fns/getDate"
import addDays from "date-fns/addDays"

import cloneDeep from "lodash/cloneDeep"
import omit from "lodash/omit"
import { setStoreRestrictions } from "../actions/stores/setStores"

export const removePastPickups = async (
  { defaultPerSlot, restrictionByDate: restrictions },
  dispatch
) => {
  const today = addDays(new Date(), 1) //One day buffer
  const year = getYear(today)
  const month = getMonth(today)
  const day = getDate(today)
  let newRestrictions = cloneDeep(restrictions)

  //For each store
  for (let store of Object.keys(restrictions)) {
    //Remove past years
    for (let rYear of Object.keys(restrictions[store])) {
      if (rYear < year) {
        newRestrictions[store] = omit(newRestrictions[store], rYear)
      }
    }

    //Remove past months from this year
    if (newRestrictions[store]?.[year]) {
      for (let rMonth of Object.keys(newRestrictions?.[store]?.[year])) {
        if (rMonth < month) {
          newRestrictions[store][year] = omit(
            newRestrictions[store]?.[year],
            rMonth
          )
        }
      }
    }
  }

  if (JSON.stringify(restrictions) !== JSON.stringify(newRestrictions)) {
    await firebase
      .auth()
      .currentUser.getIdToken()
      .then(async token => {
        await fetch(`/.netlify/functions/shopify-metafields`, {
          method: "post",
          headers: {
            token,
          },
          body: JSON.stringify({
            key: `restrictionByDate`,
            value: newRestrictions,
          }),
        })
          .then(res => res.json())
          .then(data => {
            dispatch(
              setStoreRestrictions({
                defaultPerSlot,
                restrictionByDate: newRestrictions,
              })
            )
          })
      })
  }
}
