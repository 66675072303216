// import firebase from "gatsby-plugin-firebase"
// import "firebase/database"
import fetch from "cross-fetch"

export const setPickupLocations = () => dispatch => {
  if (typeof window === "undefined") return

  fetch(`${window.location.origin}/.netlify/functions/store-locations`)
    .then(res => res.json())
    .then(stores => {
      if (stores.completed) {
        const locations = stores.data.locations.edges.reduce(
          (acc, currentVal) => {
            // const id = firebaseNameFormatter(currentVal.node.id)
            acc[currentVal.node.id] = { ...currentVal.node }
            return acc
          },
          {}
        )

        dispatch({
          type: "SET_STORES_LOCATIONS",
          locations,
        })
        return dispatch(setStoreHours())
      }
    })
    .catch(err => window.alert(err.message))
}

const setStoreHours = () => (dispatch, getState) => {
  if (typeof window === "undefined") return
  const { locations } = getState().stores

  fetch(`${window.location.origin}/.netlify/functions/store-hours`)
    .then(res => res.json())
    .then(data => {
      if (data.completed) {
        let storeHours, defaultPerSlot, restrictionByDate, pickupHours

        for (let metafield of data.data.shopMetafields) {
          if (metafield.key === "store_hours") {
            storeHours = JSON.parse(metafield.value)
          } else if (metafield.key === "defaultPerSlot") {
            defaultPerSlot = JSON.parse(metafield.value)
          } else if (metafield.key === "restrictionByDate") {
            restrictionByDate = JSON.parse(metafield.value)
          } else if (metafield.key === "pickup_hours") {
            pickupHours = JSON.parse(metafield.value)
          }
        }

        dispatch({
          type: "ADD_RESTRICTIONS_TO_STORE",
          restrictions: {
            defaultPerSlot,
            restrictionByDate,
          },
        })

        Object.keys(storeHours).forEach(id => {
          if (locations[id]) {
            dispatch({
              type: "ADD_STORE_HOURS_TO_LOCATION",
              storeHours: storeHours[id],
              id,
            })
          }
        })

        if (pickupHours) {
          Object.keys(storeHours).forEach(id => {
            if (locations[id]) {
              dispatch({
                type: "ADD_PICKUP_HOURS_TO_LOCATION",
                pickupHours: pickupHours[id],
                id,
              })
            }
          })
        }
      }
    })
}

export const setStoreRestrictions = restrictions => ({
  type: "SET_STORE_RESTRICTIONS",
  restrictions,
})

//Scraping Instagram is not working anymore.
// const setInstagram = () => async dispatch => {
//   await fetch("/.netlify/functions/instagram")
//     .then(res => res.json())
//     .then(data => {
//       if (data.completed) {
//         dispatch({
//           type: "INITIALIZE_INSTAGRAM",
//           instagram: data.data || [],
//         })
//       }
//     })
// }

export const setStoreInitialize = () => async (dispatch, getState) => {
  if (typeof window === "undefined") return
  await fetch(`${window.location.origin}/.netlify/functions/store-initialize`)
    .then(res => res.json())
    .then(data => {
      if (data.completed) {
        let hours, defaultPerSlot, restrictionByDate, pickupHours, endOfDay

        for (let metafield of data.data.shopMetafields) {
          if (metafield.key === "store_hours") {
            hours = JSON.parse(metafield.value)
          } else if (metafield.key === "defaultPerSlot") {
            defaultPerSlot = JSON.parse(metafield.value)
          } else if (metafield.key === "restrictionByDate") {
            restrictionByDate = JSON.parse(metafield.value)
          } else if (metafield.key === "pickup_hours") {
            pickupHours = JSON.parse(metafield.value)
          } else if (metafield.key === "endOfDay") {
            endOfDay = JSON.parse(metafield.value)
          }
        }

        const locations = data.data.locations.locations.edges.reduce(
          (acc, currentVal) => {
            const { id } = currentVal.node

            acc[id] = {
              ...currentVal.node,
              hours: { ...hours[id] },
              pickupHours: pickupHours ? { ...pickupHours[id] } : null,
            }
            return acc
          },
          {}
        )

        //Scrape Instagram deprecated
        // if (data.data.posts.length === 0) {
        //   dispatch(setInstagram())
        // }

        dispatch({
          type: "INITIAL_SET_STORES",
          locations,
          instagram: data.data.posts || undefined,
          restrictions: {
            defaultPerSlot,
            restrictionByDate,
            endOfDay,
          },
        })
      } else {
        dispatch(setPickupLocations())
        // dispatch(setInstagram()) //Scrape Instagram deprecated
      }
    })
}
