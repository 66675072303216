const adminPage = (
  state = {
    user: null,
    connected: false,
    decodedToken: null,
    loading: true,
    loadingInProgress: 0,
  },
  action
) => {
  switch (action.type) {
    case "SET_ADMIN_USER": {
      return { ...state, user: action.user, connected: action.user != null }
    }
    case "SET_DECODED_TOKEN": {
      return {
        ...state,
        decodedToken: action.decodedToken,
      }
    }
    case "SET_ADMIN_LOADING": {
      return {
        ...state,
        loading: action.loading,
      }
    }

    case "INCREASE_ADMIN_LOADING": {
      const loadingInProgress = state.loadingInProgress + 1
      return {
        ...state,
        loadingInProgress,
        loading: loadingInProgress === 0 ? false : true,
      }
    }
    case "DECREASE_ADMIN_LOADING": {
      //Some actions call decrease regardless. This is to prevent going below 0.
      const loadingInProgress = Math.max(state.loadingInProgress - 1, 0)
      return {
        ...state,
        loadingInProgress,
        loading: loadingInProgress === 0 ? false : true,
      }
    }

    default:
      return state
  }
}

export default adminPage
