import React from "react"
import { Provider } from "react-redux"

import CartClient from "./src/components/CartClient/CartClient"
import { ApolloProvider } from "@apollo/client"
import { store } from "./src/lib/reduxStore"
import "./src/fonts/fonts.css"
import { setStoreInitialize } from "./src/actions/stores/setStores"
import client from "./src/lib/shopifyApollo"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  // store.dispatch(setPickupLocations())
  store.dispatch(setStoreInitialize())

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <CartClient>{element}</CartClient>
      </Provider>
    </ApolloProvider>
  )
}
