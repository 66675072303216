import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { connect } from "react-redux"

import { toggleSelectHeader } from "../../actions/toggleSelectHeader"

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const TabContainer = styled.div`
  position: relative;
`

const StyledLink = styled(Link)`
  margin: 5px;
  font-family: Sweet Sans Pro;
  font-size: 16px;
  color: black;
  text-decoration: none;
`
const TabHighlight = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  background-color: ${({ display }) => display};
`

const NavMenu = ({ siteLocation, dispatch }) => {
  return (
    <Container>
      <TabContainer>
        <StyledLink
          to="/shop/all"
          onMouseEnter={() => dispatch(toggleSelectHeader(true))}
        >
          Shop
        </StyledLink>
        <TabHighlight
          display={
            siteLocation === "/shop" ? "var(--secondary-color)" : "transparent"
          }
        />
      </TabContainer>
      <TabContainer>
        <StyledLink to="/hours">Location & Hours</StyledLink>
        <TabHighlight
          display={
            siteLocation === "/hours" ? "var(--secondary-color)" : "transparent"
          }
        />
      </TabContainer>
      <TabContainer>
        <StyledLink to="/about-us">About Us</StyledLink>
        <TabHighlight
          display={
            siteLocation === "/about-us"
              ? "var(--secondary-color)"
              : "transparent"
          }
        />
      </TabContainer>
    </Container>
  )
}

const mapStatetoProps = ({ siteLocation }) => {
  return { siteLocation }
}

export default connect(mapStatetoProps)(NavMenu)
