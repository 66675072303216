import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { connect } from "react-redux"

import Cart from "./Cart/Cart"
import breakPoints from "../../APIs/devices"
import { toggleSideModal } from "../../actions/toggleSideModal"
import useMoveOut from "../../APIs/useMoveOut"

const Wrapper = styled(animated.div)`
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  width: 33vw;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding-top: var(--header-height-mobile);
  z-index: var(--cart-modal-zIndex);
  box-shadow: 0 -5px 5px rgb(0, 0, 0, 0.2);
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding-top: var(--header-height);
  }
`

const SideModal = ({ dispatch, showSideModal: { show, version } }) => {
  const transitions = useTransition(show, null, {
    from: { transform: "translate3d(0,-100%,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(0,-100%,0)" },
  })

  const [blockRef, setBlockRef] = useState(null)

  useEffect(() => {
    setBlockRef(document.getElementById("icons-block"))
  }, [])

  const modalRef = useRef(null)

  useMoveOut([modalRef, { current: blockRef }], () =>
    dispatch(toggleSideModal(false))
  )

  return (
    <div>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper ref={modalRef} key={key} style={props}>
              {version === "cart" ? <Cart /> : null}
            </Wrapper>
          )
      )}
    </div>
  )
}

const mapStateToProps = ({ showSideModal }) => {
  return { showSideModal }
}

export default connect(mapStateToProps)(React.memo(SideModal))
