import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setCart } from "../../actions/setCart"
import { setUser } from "../../actions/setAccount"
import firebase from "gatsby-plugin-firebase"
import "firebase/database"

//date-fns
import getYear from "date-fns/getYear"
import getMonth from "date-fns/getMonth"
import getDate from "date-fns/getDate"
import getHours from "date-fns/getHours"
import getMinutes from "date-fns/getMinutes"

import client from "../../lib/shopifyApollo"
import { gql } from "@apollo/client"

const CREATE_CART = gql`
  mutation cartCreate {
    cartCreate {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`

const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      id
      attributes {
        key
        value
      }
      cost {
        subtotalAmount {
          amount
        }
      }
      totalQuantity
      lines(first: 20) {
        edges {
          node {
            id
            quantity
            attributes {
              key
              value
            }
            discountAllocations {
              discountedAmount {
                amount
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                selectedOptions {
                  name
                  value
                }
                price {
                  amount
                }
                product {
                  id
                  title
                  handle
                }
                image {
                  id
                  url(transform: { maxWidth: 125, maxHeight: 125 })
                }
              }
            }
          }
        }
      }
      checkoutUrl
    }
  }
`

const CartClient = ({ children, dispatch }) => {
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    initializeCheckout()
    getUser()
  }, [])

  const getUser = () => {
    try {
      if (isBrowser) {
        const user = JSON.parse(localStorage.getItem("user_access_token"))
        dispatch(setUser(user))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getNewId = async () => {
    try {
      const newCart = await client.mutate({
        mutation: CREATE_CART,
      })

      const {
        data: {
          cartCreate: { cart },
        },
      } = newCart

      if (isBrowser) {
        if (!cart.id) {
          console.log(`${cart.userErrors?.code}: ${cart.userErrors.message}`)
          return
        }

        localStorage.setItem("checkout_id", cart.id)
      }
      return cart
    } catch (e) {
      console.error(e)
    }
  }

  const initializeCheckout = async () => {
    try {
      //Check if id exists
      const currentCheckoutId = isBrowser
        ? localStorage.getItem("checkout_id")
        : null

      let newCart = null

      try {
        //If id exists, fetch checkout from Shopify
        newCart = await client
          .query({
            query: GET_CART,
            variables: {
              id: currentCheckoutId,
            },
          })
          .then(({ data: { cart } }) => cart)
      } catch (e) {
        console.error(e)
        newCart = await getNewId()
      }

      //If id does not exist, create new checkout. NOT needed anymore.
      // if (!newCart) newCart = await getNewId()
      if (!currentCheckoutId) newCart = await getNewId()

      // Because Shopify changed checkout to cart, the property completedAt was
      // removed. The only way to retrieve the pick up times is to save them to
      // local storage after the cart is complete. If the there is a stored
      // cartID and the cart is null, the purchase must've been complete.
      if (currentCheckoutId && newCart === null && isBrowser) {
        const attributes = JSON.parse(localStorage.getItem("cart_attributes"))
        const reducedCustomAttributes = attributes.reduce((acc, cv) => {
          acc[cv.key] = cv.value
          return acc
        }, {})

        const location = reducedCustomAttributes["pickup-location"]
        const date = reducedCustomAttributes["raw-date"]
        const newDate = new Date(date)
        const year = getYear(newDate)
        const month = getMonth(newDate) + 1 //getMonth starts at 0
        const day = getDate(newDate)
        const hour = getHours(newDate)
        const minute = getMinutes(newDate)

        //Check if checkout has already been saved to firebase.
        const pickupExists = await firebase
          .database()
          .ref(`pickups/${location}/${year}/${month}/${day}/${hour}/${minute}`)
          .once("value")
          .then(snapshot => {
            if (snapshot.val() === null) {
              return false
            } else {
              return Object.keys(snapshot.val()).includes(currentCheckoutId)
            }
          })

        if (pickupExists) {
          newCart = await getNewId()
        } else {
          const savePickup = await firebase
            .database()
            .ref(
              `pickups/${location}/${year}/${month}/${day}/${hour}/${minute}`
            )
            .update({
              [currentCheckoutId]: date,
            })
            .then(res => {
              return { completed: true }
            })
            .catch(err => {
              console.error(err)
              return { completed: false }
            })

          if (savePickup.completed) {
            newCart = await getNewId()
          }
        }

        localStorage.setItem("cart_attributes", "")
      }

      if (!newCart) newCart = await getNewId()

      //Set checkout to state
      dispatch(setCart(newCart))
    } catch (e) {
      console.error(e)
    }
  }

  return <>{children}</>
}

export default connect()(CartClient)
