import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import LineItem from "./LineItem"
import Footer from "./Footer"

const Container = styled.div`
  position: relative;
  flex-flow: column;
  height: 100%;
  padding: 20px;
`
const LineItemWrapper = styled.div`
  position: relative;
  height: 80%;
  overflow-y: auto;
`

const Cart = ({ cart }) => {
  return cart === null || cart.lines === undefined ? (
    <></>
  ) : (
    <Container>
      <LineItemWrapper>
        {cart.lines.edges.map(({ node }) => {
          return <LineItem key={node.id} node={node} />
        })}
      </LineItemWrapper>
      <Footer subtotal={cart.cost.subtotalAmount} />
    </Container>
  )
}

const mapStateToProps = ({ cart }) => {
  return { cart }
}

export default connect(mapStateToProps)(React.memo(Cart))
