const mobileSite = (state = true, action) => {
  switch (action.type) {
    case "TOGGLE_MOBILE_SITE": {
      return action.mobile
    }
    default:
      return state
  }
}

export default mobileSite
