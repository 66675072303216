import React from "react"
import AdminLayout from "./src/components/AdminLayout"
import Layout from "./src/components/layout"

export default ({ element, props }) => {
  if (props.uri !== "/admin") {
    return <Layout {...props}>{element}</Layout>
  }

  if (props.uri === "/admin") {
    return <AdminLayout {...props}>{element}</AdminLayout>
  }

  return element
}
