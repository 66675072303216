import React from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { useSelector } from "react-redux"
import breakPoints from "../../APIs/devices"

const Container = styled(animated.div)`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  height: 70px;
  max-width: 50%;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: var(--black);
  z-index: 1000;
  color: white;
  text-align: center;
  font-family: Sweet Sans Pro;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${breakPoints.lg}) {
    left: unset;
    right: 50px;
  }
`

const Toast = () => {
  const addToCartPopup = useSelector(state => state.addToCartPopup)
  const transition = useTransition(addToCartPopup.on > 0, null, {
    from: { transform: "translate3d(0%, 100%, 0)" },
    enter: { transform: "translate3d(0%, -40%, 0)" },
    leave: { transform: "translate3d(0%, 100%, 0)" },
  })

  return (
    <>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              {addToCartPopup.content}
            </Container>
          )
      )}
    </>
  )
}

export default Toast
