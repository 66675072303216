import omit from "lodash/omit"

const macaronPage = (
  state = {
    boxCap: 0,
    macaronsUnfiltered: [],
    notCompleteWarning: false,
    previousSelection: [],
    previousSelectedMacarons: {},
    selection: [],
    selectedMacarons: {},
  },
  action
) => {
  switch (action.type) {
    case "SET_MACARONS_UNFILTERED": {
      return {
        ...state,
        macaronsUnfiltered: action.macarons,
      }
    }
    case "ADD_MACARON": {
      const { selection, selectedMacarons } = state
      const newSelection = selection.concat([action.macaron])
      const { title, handle } = action.macaron.node

      return {
        ...state,
        selection: newSelection,
        selectedMacarons: {
          ...selectedMacarons,
          [handle]: selectedMacarons[handle]
            ? {
                ...selectedMacarons[handle],
                amount: selectedMacarons[handle].amount + 1,
              }
            : { amount: 1, title },
        },
      }
    }
    case "SET_PREVIOUS_MACARON_SELECTION_STATE": {
      return {
        ...state,
        previousSelection: state.selection,
        previousSelectedMacarons: state.selectedMacarons,
      }
    }
    case "REMOVE_MACARON": {
      const { selection, selectedMacarons } = state
      const index = selection.indexOf(action.macaron)
      selection.splice(index, 1)
      const handle = action.macaron.node.handle

      const newSelectedMacarons =
        selectedMacarons[handle].amount === 1
          ? omit(selectedMacarons, handle)
          : {
              ...selectedMacarons,
              [handle]: {
                ...selectedMacarons[handle],
                amount: selectedMacarons[handle].amount - 1,
              },
            }

      return {
        ...state,
        selection,
        selectedMacarons: newSelectedMacarons,
      }
    }
    case "CANCELLED_AND_RESET_SELECTION": {
      return {
        ...state,
        selection: state.previousSelection,
        selectedMacarons: state.previousSelectedMacarons,
      }
    }
    case "REMOVE_ALL_SELECTED_MACARONS": {
      return {
        ...state,
        selection: [],
        selectedMacarons: {},
      }
    }
    case "SET_BOX_CAPACITY": {
      return {
        ...state,
        boxCap: action.cap,
      }
    }
    case "NOT_COMPLETE_MACARONS_WARNING": {
      return {
        ...state,
        notCompleteWarning: action.toggle,
      }
    }
    default:
      return state
  }
}

export default macaronPage
