const isBrowser = typeof window !== "undefined"

export const loginUser = accessToken => dispatch => {
  try {
    if (isBrowser) {
      localStorage.setItem("user_access_token", JSON.stringify(accessToken))
    }
    dispatch(setUser(accessToken))
  } catch (e) {
    console.error(e)
  }
}

export const setUser = accessToken => ({
  type: "LOGIN_USER",
  accessToken,
})

export const userInfo = accessToek => ({})
