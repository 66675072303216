import { useEffect } from "react"

const isBrowser = typeof window !== `undefined`

let supportsPassive = false
try {
  if (isBrowser) {
    var opts = Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true
        return supportsPassive
      },
    })
    window.addEventListener("testPassive", null, opts)
    window.removeEventListener("testPassive", null, opts)
  }
} catch (e) {}

// Hook
function useMoveOut(refArray, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements

      for (const ref of refArray) {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
      }

      handler(event)
    }

    document.addEventListener("mouseout", listener, {
      passive: supportsPassive,
    })

    return () => {
      document.removeEventListener("mouseout", listener, {
        passive: supportsPassive,
      })
    }
  }, [refArray, handler])
}

export default useMoveOut
