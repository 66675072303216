import React from "react"
import styled from "styled-components"

function getWidthString(span) {
  if (!span) return
  if (span > 12) span = 12

  let width = (span / 12) * 100
  return {
    "flex-basis": `${width}%`,
    "max-width": `${width}%`,
    // width: `${width}%`,
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  ${({ margin }) => `margin: -${margin}px;`}
`
export const FlexContainer = props => {
  const { children, ...rest } = props
  return (
    <Container {...rest}>
      {React.Children.toArray(children).map((child, index) => {
        if (typeof child === "object") {
          //Plain texts are not objects.

          return React.cloneElement(child, {
            key: index,
            padding: rest.margin,
          })
        }
        return child
      })}
    </Container>
  )
}

const Item = styled.div`
  float: left;
  display: ${({ container }) => (container === "true" ? "flex" : "")};
  padding: ${({ padding }) => padding && `${padding}px`};
  ${({ xs }) =>
    xs
      ? getWidthString(xs)
      : { "flex-basis": "100%" }} @media
    only screen and (min-width: 425px) {
    ${({ sm }) => sm && getWidthString(sm)}
  }
  @media only screen and (min-width: 768px) {
    ${({ md }) => md && getWidthString(md)}
  }
  @media only screen and (min-width: 1024px) {
    ${({ lg }) => lg && getWidthString(lg)}
  }
  @media only screen and (min-width: 1440px) {
    ${({ xl }) => xl && getWidthString(xl)}
  }
`
export const FlexItem = ({ children, container, ...style }) => {
  return (
    <Item container={container ? "true" : "false"} {...style}>
      {children}
    </Item>
  )
}
