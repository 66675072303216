import React, { useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
import "./layout.css"

import Header from "./Header/Header"
import SideModal from "./SideModal/SideModal"
import "./layout.css"
import { toggleMobile } from "../actions/setMobile"
import useWindowDimensions from "../APIs/window_dimensions"
import { toggleSelectHeader } from "../actions/toggleSelectHeader"
import lockBody from "../APIs/lockBody"
import { breakPointsInt } from "../APIs/devices"
import Toast from "./Toast/Toast"
import Footer from "./Footer"

const ViewPort = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  /* overflow-y: hidden; */
`

const Main = styled.main`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
`

const Layout = ({
  dispatch,
  mobileSite,
  isSideBarOpen,
  showSideModal,
  children,
}) => {
  const { width } = useWindowDimensions()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    lockBody(showSideModal.show)
  }, [showSideModal.show])

  //Check if browser is mobile size
  useLayoutEffect(() => {
    if (width >= breakPointsInt.lg) {
      dispatch(toggleMobile(false))
    } else {
      dispatch(toggleMobile(true))
    }
  }, [dispatch, width])

  useEffect(() => {
    lockBody(isSideBarOpen)
  }, [dispatch, isSideBarOpen])

  return (
    <>
      <ViewPort
        className="view-port"
        id="view-port"
        // locked={showSideModal.show}
      >
        <Toast />
        <Header siteTitle={data.site.siteMetadata.title} />
        {mobileSite ? null : <SideModal />}

        <Main
          id="layout-main"
          onMouseEnter={() => {
            dispatch(toggleSelectHeader(false))
          }}
        >
          {children}
        </Main>
        <Footer />
      </ViewPort>
    </>
  )
}

const mapStateToProps = ({ mobileSite, showSideModal, isSideBarOpen }) => {
  return { mobileSite, showSideModal, isSideBarOpen }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(mapStateToProps)(Layout)
