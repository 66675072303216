import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"

import reducer from "../reducers/index"

//Redux
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
      compose
    : compose

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
)
