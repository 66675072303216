const breakPoints = {
  xs: "320px",
  sm: "425px",
  md: "768px",
  lg: "1025px",
  xl: "1440px",
}

export const breakPointsInt = {
  xs: 320,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1440,
}

export default breakPoints
