import { ApolloClient, InMemoryCache } from "@apollo/client"

// // //Apollo
// const client = new ApolloClient({
//   uri: `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2025-01/graphql.json`,
//   headers: {
//     Accept: "application/json",
//     "X-Shopify-Storefront-Access-Token":
//       process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
//   },
// })

const client = new ApolloClient({
  uri: `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2025-01/graphql.json`,
  headers: {
    Accept: "application/json",
    "X-Shopify-Storefront-Access-Token":
      process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  cache: new InMemoryCache(),
})

export default client
