import React from "react"
import styled from "styled-components"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"
import { Link, navigate } from "gatsby"
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai"
import breakPoints from "../APIs/devices"

const Root = styled.footer`
  position: relative;
  background-color: var(--black);
  color: white;
  width: 100%;
  max-width: 100%;
  padding: 3rem 0;
  overflow: hidden;
  flex: 1 1 auto;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding: 5rem 0;
  }
`

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 20px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    margin: 0 auto;
  }
`

const FooterGrid = styled.div`
  display: grid;
  margin-bottom: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
`

const ListItem = styled.li`
  font-family: Sweet Sans Pro;
  font-size: 1.3rem;
  margin-bottom: 1.3rem;
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: lightgrey;
  }
`

const Instagram = styled(AiFillInstagram)`
  color: white;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
    color: lightgrey;
  }
`

const Facebook = styled(AiFillFacebook)`
  color: white;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
    color: lightgrey;
  }
`

const GET_COLLECTION_TITLES = gql`
  query MyQuery {
    collections(first: 30, query: "-title:All") {
      edges {
        node {
          id
          title
          handle
        }
      }
    }
  }
`

const Footer = () => {
  const { loading, error, data } = useQuery(GET_COLLECTION_TITLES)

  if (error) {
    console.error(`Error occured at footer: ${error}`)
    navigate("/error")
    return null
  }

  if (loading) return null

  return (
    <Root>
      <FooterContainer>
        <FooterGrid>
          <div>
            <List>
              <ListItem>
                <h2>
                  <StyledLink to={`/shop/all`}>SHOP</StyledLink>
                </h2>
              </ListItem>
              {data &&
                data.collections.edges.map(({ node: { handle, title } }) => (
                  <ListItem key={handle}>
                    <StyledLink to={`/shop/${handle}`}>{title}</StyledLink>
                  </ListItem>
                ))}
            </List>
          </div>
          <div>
            <List>
              <h2>
                <StyledLink to="/hours">Location & Hours</StyledLink>
              </h2>
              <h2>
                <StyledLink to="/about-us">About Us</StyledLink>
              </h2>
            </List>
          </div>
        </FooterGrid>

        <div style={{ marginBottom: "2rem" }}>
          <h2>Follow Us</h2>
          <div style={{ display: "flex" }}>
            <Instagram
              onClick={() =>
                window.open("https://www.instagram.com/ruelopatisserie/")
              }
            />
            <Facebook
              onClick={() =>
                window.open("https://www.facebook.com/ruelo.richmondhill/")
              }
            />
          </div>
        </div>
        <div>Ruelo Patisserie © {new Date().getFullYear()}</div>
        <br />
        <div>
          Designed and Built by{" "}
          <a href="https://www.m3cubed.ca" style={{ color: "white" }}>
            M3Cubed Web Design and Development
          </a>
        </div>
      </FooterContainer>
    </Root>
  )
}

export default Footer
