import apolloClient from "../lib/shopifyApollo"
import { gql } from "@apollo/client"
import { navigate } from "gatsby"

const GET_PRODUCT = gql`
  query MyQuery($handle: String!) {
    product(handle: $handle) {
      variants(first: 2, reverse: true) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`

const REMOVE_LINE_ITEM = gql`
  mutation cartLinesRemove($id: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $id, lineIds: $lineIds) {
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }
`

const ADD_LINE_ITEM = gql`
  mutation cartLinesAdd($id: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $id, lines: $lines) {
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }
`

export default async function handleTaxVariants({ cart, res, rej }) {
  let total = 0
  let items = {
    tax: [],
    noTax: [],
  }

  //Check the number of "Tax" items
  for (const lineItem of cart.lines.edges) {
    const {
      node: {
        id,
        quantity,
        merchandise: {
          title,
          product: { handle },
        },
      },
    } = lineItem

    if (title === "Default") {
      total += quantity
      items.tax.push({ lineItemId: id, handle, quantity })
    } else if (title === "No Tax") {
      total += quantity
      items.noTax.push({ lineItemId: id, handle, quantity })
    }
  }

  //If there are more than 6, convert to "No Tax"
  let taxVersion = total >= 6 ? "No Tax" : "Default"

  //Change to the opposite version as the above variable
  let itemProperty = total >= 6 ? "tax" : "noTax"

  const lineItemsToRemove = items[itemProperty].map(item => item.lineItemId)

  //remove the line items
  const removeResult = await apolloClient.mutate({
    mutation: REMOVE_LINE_ITEM,
    variables: {
      id: cart.id,
      lineIds: lineItemsToRemove,
    },
  })

  const productsToAdd = []

  for await (const item of items[itemProperty]) {
    const { handle, quantity } = item

    try {
      //Wait for apollo query of the product
      const result = await apolloClient.query({
        query: GET_PRODUCT,
        variables: { handle },
        fetchPolicy: "no-cache",
      })

      //Get the variants
      const {
        error,
        data: {
          product: {
            variants: { edges: productVariants },
          },
        },
      } = result

      let merchandiseId

      //Loop the variants to find the taxless ver.
      for (const productVariant of productVariants) {
        const {
          node: { id, title },
        } = productVariant

        if (title === taxVersion) {
          merchandiseId = id
          break
        }
      }

      productsToAdd.push({
        merchandiseId,
        quantity,
        attributes: [],
      })
    } catch (e) {
      console.error(e)
      navigate("/error")
    }
  }

  if (productsToAdd.length > 0) {
    try {
      //add the taxless variant with the same quantity
      const addResult = await apolloClient.mutate({
        mutation: ADD_LINE_ITEM,
        variables: {
          id: cart.id,
          lines: productsToAdd,
        },
      })
    } catch (e) {
      console.error(e)
      alert(e)
      navigate("/error")
    }
  }

  res()
}
