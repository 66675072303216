import apolloClient from "../lib/shopifyApollo"

import handleTaxVariants from "../APIs/handleTaxVariants"
import { setCheckoutRedirecting } from "./checkout/setCheckPage"
import { navigate } from "gatsby"
import { gql } from "@apollo/client"
import { errorMessageHandler } from "../utils/apolloErrorMessageHandler"

const sortAttributes = attributes => {
  /*
  {a: something, b: something else}
  
  to

  [{key: "a", value: "something"}, {key: "b", value: "something else"}]
  */
  const output = Object.entries(attributes).reduce((accum, [k, v]) => {
    accum.push({ key: k, value: v })
    return accum
  }, [])

  return output
}

const UPDATE_ATTRIBUTES = gql`
  mutation cartAttributesUpdate($id: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $id) {
      cart {
        id
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
          }
        }
        totalQuantity
        lines(first: 30) {
          edges {
            node {
              id
              quantity
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                  product {
                    id
                    title
                    handle
                  }
                  image {
                    id
                    url(transform: { maxWidth: 125, maxHeight: 125 })
                  }
                }
              }
            }
          }
        }
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`

const ADD_LINE_ITEMS = gql`
  mutation cartLinesAdd($id: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $id, lines: $lines) {
      cart {
        id
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
          }
        }
        totalQuantity
        lines(first: 30) {
          edges {
            node {
              id
              quantity
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                  product {
                    id
                    title
                    handle
                  }
                  image {
                    id
                    url(transform: { maxWidth: 125, maxHeight: 125 })
                  }
                }
              }
            }
          }
        }
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`

const REMOVE_LINE_ITEM = gql`
  mutation cartLinesRemove($id: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $id, lineIds: $lineIds) {
      cart {
        id
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
          }
        }
        totalQuantity
        lines(first: 30) {
          edges {
            node {
              id
              quantity
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                  product {
                    id
                    title
                    handle
                  }
                  image {
                    id
                    url(transform: { maxWidth: 125, maxHeight: 125 })
                  }
                }
              }
            }
          }
        }
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`

const UPDATE_LINE_ITEM = gql`
  mutation cartLinesUpdate($id: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $id, lines: $lines) {
      cart {
        id
        attributes {
          key
          value
        }
        cost {
          subtotalAmount {
            amount
          }
        }
        totalQuantity
        lines(first: 30) {
          edges {
            node {
              id
              quantity
              attributes {
                key
                value
              }
              discountAllocations {
                discountedAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                  product {
                    id
                    title
                    handle
                  }
                  image {
                    id
                    url(transform: { maxWidth: 125, maxHeight: 125 })
                  }
                }
              }
            }
          }
        }
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const addProductToCart = ({
  variantId,
  quantity,
  customAttributes,
}) => async (dispatch, getState) => {
  try {
    const newAttributes = customAttributes && sortAttributes(customAttributes)
    const lines = [
      {
        merchandiseId: variantId,
        quantity: quantity ? quantity : 1,
        attributes: customAttributes && newAttributes,
      },
    ]

    const newCart = await apolloClient.mutate({
      mutation: ADD_LINE_ITEMS,
      variables: {
        id: getState().cart.id,
        lines,
      },
    })
    const {
      data: {
        cartLinesAdd: { cart, userErrors },
      },
    } = newCart
    if (userErrors.length > 0) errorMessageHandler(userErrors)

    dispatch(setCart(cart))
  } catch (e) {
    console.error(e)
  }
}

export const removeProductFromCart = ({ id: lineItemId, res }) => async (
  dispatch,
  getState
) => {
  try {
    const newCart = await apolloClient.mutate({
      mutation: REMOVE_LINE_ITEM,
      variables: {
        id: getState().cart.id,
        lineIds: [lineItemId],
      },
    })

    const {
      data: {
        cartLinesRemove: { cart, userErrors },
      },
    } = newCart

    if (userErrors.length > 0) errorMessageHandler(userErrors)

    dispatch(setCart(cart))
    if (res) {
      return res()
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param update {id: string, quantity: number}
 * @returns
 */
export const updateProduct = ({ res, rej, ...update }) => async (
  dispatch,
  getState
) => {
  try {
    const newCart = await apolloClient.mutate({
      mutation: UPDATE_LINE_ITEM,
      variables: {
        id: getState().cart.id,
        lines: [update],
      },
    })

    const {
      data: {
        cartLinesUpdate: { cart, userErrors },
      },
    } = newCart

    if (userErrors.length > 0) errorMessageHandler(userErrors)

    dispatch(setCart(cart))

    if (res) {
      return res()
    }
  } catch (e) {
    console.error(e)
  }
}

export const setCart = cart => ({
  type: "SET_CART",
  cart,
})

export const updatePickupDateForCheckout = attribute => async (
  dispatch,
  getState
) => {
  dispatch(setCheckoutRedirecting(true))

  try {
    const formattedAttr = sortAttributes(attribute)

    const result = await new Promise((res, rej) =>
      handleTaxVariants({ cart: getState().cart, res, rej })
    )

    //Update the pickup time & location to cart
    const newCart = await apolloClient.mutate({
      mutation: UPDATE_ATTRIBUTES,
      variables: {
        id: getState().cart.id,
        attributes: formattedAttr,
      },
    })

    // Because Shopify changed checkout to cart, the property completedAt was
    // removed. The only way to retrieve the pick up times is to save them to
    // local storage after the cart is complete. If the there is a stored cartID
    // and the cart is null, the purchase must've been complete.
    if (typeof window !== "undefined")
      localStorage.setItem("cart_attributes", JSON.stringify(formattedAttr))

    const {
      data: {
        cartAttributesUpdate: { cart, userErrors },
      },
    } = newCart

    if (userErrors.length > 0) errorMessageHandler(userErrors)

    dispatch(setCart(cart))

    return (window.location = cart.checkoutUrl)
  } catch (e) {
    console.error(`Error occured at set cart: ${e}`)
    navigate("/error")
  }
}
