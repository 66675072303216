import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import breakPoints from "../../APIs/devices"
import RueloLogo from "../../images/RueloLogo.png"

const Container = styled.div`
  position: relative;
  height: var(--header-height-mobile);
  display: flex;
  justify-content: center;
  padding: 6px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    max-width: 135px;
    height: var(--header-height);
  }
  @media only screen and (min-width: ${breakPoints.xl}) {
    left: 10vw;
  }
`

const Image = styled.img`
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  object-position: center center;
`

const HeaderLogo = () => {
  return (
    <Container onClick={() => navigate("/")}>
      <Image src={RueloLogo} alt="Ruelo-Logo" />
    </Container>
  )
}

export default HeaderLogo
