import firebase from "gatsby-plugin-firebase"
import "firebase/auth"
import "firebase/database"

/* Listeners called on:
/stores
/store addresses
/store hours
/products preorder
*/

export const adminLogout = () => dispatch => {
  //Turn off listeners
  firebase.database().ref("/stores").off()
  firebase.database().ref("/store addresses").off()
  firebase.database().ref("/store hours").off()
  firebase.database().ref("/products preorder").off()

  return dispatch({
    type: "ADMIN_LOGOUT",
  })
}

export const setAdmin = () => async dispatch => {
  dispatch(increaseAdminLoading())
  await firebase.auth().onAuthStateChanged(user => {
    if (user === null) {
      return dispatch(decreaseAdminLoading())
    }
    dispatch({
      type: "SET_ADMIN_USER",
      user,
    })
    return dispatch(decreaseAdminLoading())
  })
}

export const setDecodedToken = () => async dispatch => {
  dispatch(increaseAdminLoading())
  const completed = await firebase
    .auth()
    .currentUser.getIdToken()
    .then(IdToken =>
      fetch("/.netlify/functions/auth", {
        headers: {
          token: IdToken,
        },
      })
        .then(res => {
          let json
          try {
            json = res.json()
            return json
          } catch (err) {
            dispatch({
              type: "SET_DECODED_TOKEN",
              decodedToken: res,
            })
          }
        })
        .then(data => {
          dispatch({
            type: "SET_DECODED_TOKEN",
            decodedToken: data,
          })
          return true
        })
        .catch(err => {
          console.error(err)
          return true
        })
    )
  if (completed) {
    return dispatch(decreaseAdminLoading())
  }
}

export const setAdminLoading = loading => ({
  type: "SET_ADMIN_LOADING",
  loading,
})

export const increaseAdminLoading = () => ({
  type: "INCREASE_ADMIN_LOADING",
})

export const decreaseAdminLoading = () => ({
  type: "DECREASE_ADMIN_LOADING",
})
