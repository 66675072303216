import React, { useState, useRef } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { animated } from "react-spring"
import { connect } from "react-redux"
import { gql } from "@apollo/client"
import { useQuery } from "@apollo/client"

import { toggleSideBar } from "../../actions/toggleSideBar"
import breakPoints from "../../APIs/devices"

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  padding-top: var(--header-height-mobile);
  padding-left: 30px;
  max-width: 500px;
  background: white;
  z-index: var(--mobile-menu-zIndex);
  overflow: auto;
  @media only screen and (min-width: ${breakPoints.lg}) {
    padding-top: var(--header-height);
  }
`
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: gray;
  opacity: 0.5;
  display: ${({ display }) => display};
`

const StyledLink = styled(Link)`
  position: relative;
  color: black;
  text-decoration: none;
`

const Header = styled.h2`
  position: relative;
  margin: 0;
`

const List = styled.ul`
  position: relative;
  list-style: none;
  margin-top: 1rem;
`

const CollectionList = styled(List)`
  max-height: ${({ expand }) => (expand ? "3000px" : "0px")};
  overflow: hidden;
  margin-top: ${({ expand }) => (expand ? "1rem" : "0px")};
  /* display: ${({ expand }) => (expand ? "" : "none")}; */
  transition: all 250ms ease-in-out;
`

const ListItem = styled.li`
  position: relative;
  font-family: Sweet Sans Pro;
  font-size: 1.3rem;
  margin-bottom: 1.3rem;
  &:hover {
    cursor: pointer;
  }
`

const GET_COLLECTION_TITLES = gql`
  query {
    collections(first: 30, sortKey: TITLE) {
      edges {
        node {
          handle
          title
        }
      }
    }
  }
`

const MenuSideBar = ({ isSideBarOpen, dispatch, style }) => {
  const [expandShop, setExpandShop] = useState(false)

  const wrapperRef = useRef(null)

  const { error, data } = useQuery(GET_COLLECTION_TITLES)

  if (error) {
    console.error(`Error occured at menu side bar: ${error}`)
    navigate("/error")
    return null
  }

  return (
    <>
      <Background
        onClick={() => dispatch(toggleSideBar(false))}
        display={isSideBarOpen ? "initial" : "none"}
      />
      <Container ref={wrapperRef} style={style}>
        <List>
          <ListItem>
            <Header onClick={() => setExpandShop(!expandShop)}>Shop</Header>

            <CollectionList expand={expandShop}>
              {data?.collections?.edges?.map(edge => (
                <ListItem key={edge.node.handle}>
                  <StyledLink
                    to={`/shop/${edge.node.handle}`}
                    onClick={() => dispatch(toggleSideBar(false))}
                  >
                    {edge.node.title}
                  </StyledLink>
                </ListItem>
              ))}
            </CollectionList>
          </ListItem>
          <ListItem>
            <Header>
              <StyledLink
                onClick={() => dispatch(toggleSideBar(false))}
                to="/hours"
              >
                Locations & Hours
              </StyledLink>
            </Header>
          </ListItem>
          <ListItem>
            <Header>
              <StyledLink
                onClick={() => dispatch(toggleSideBar(false))}
                to="/about-us"
              >
                About Us
              </StyledLink>
            </Header>
          </ListItem>
        </List>
      </Container>
    </>
  )
}

const mapStateToProps = ({ isSideBarOpen }) => {
  return { isSideBarOpen }
}

export default connect(mapStateToProps)(MenuSideBar)
