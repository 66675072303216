import { combineReducers } from "redux"
import adminPage from "./adminPage"
import manageStores from "./manageStores"
import productsPreorder from "./productsPreorder"
import reports from "./reports"
import createOrder from "./createOrder"
import pickupSchedule from "./pickupSchedule"

const appReducer = combineReducers({
  createOrder,
  general: adminPage,
  manageStores,
  pickupSchedule,
  productsPreorder,
  reports,
})

const rootReducer = (state, action) => {
  if (action.type === "ADMIN_LOGOUT") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
