const siteLocation = (state = "index", action) => {
  switch (action.type) {
    case "SET_LOCATION": {
      return action.pathname
    }
    default:
      return state
  }
}

export default siteLocation
